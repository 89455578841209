import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import HeaderBottom from '../../components/HomePage/HeaderBottom';


const FooterContact = () => {

    const [footeraddress, setAddressfooter] = useState([]);

    useEffect(() => {
        fetch(`https://lead2cloneclone-36ae8168f906.herokuapp.com/footer-address`)
            .then((res) => res.json())
            .then((info) => setAddressfooter(info));
    }, []);


    const handlefooterAddress = (event) => {
        event.preventDefault();

        const Location = event.target.Location.value;
        const Phone = event.target.Phone.value;
        const footerEmail = event.target.footerEmail.value;




        const contactSection = {

            Location,
            Phone,
            footerEmail,




        };

        const url = `https://lead2cloneclone-36ae8168f906.herokuapp.com/add-footer-address`;
        fetch(url, {
            method: "POST",
            headers: {
                "content-type": "application/json",
            },
            body: JSON.stringify(contactSection),
        })
            .then((res) => res.json())
            .then((result) => {

                alert('Footer Address is Updated');
            });
    };



    return (
        <>
        <HeaderBottom></HeaderBottom>

            <div className='container   '>
                <section id="services" class="services-area mt-5 mb-5 fix" >



                    <div class="container">
                        <div class="row">

                        </div>
                        <div class="row">

                            <div class="col-lg-8 col-md-12">


                                <h2 className='mt-5'> Update Footer Contact </h2>

                                {
                                    footeraddress.length === 1 &&
                                    <>
                                        {
                                            footeraddress.map(c =>
                                                footeraddress.map(c =>
                                                    <Link class="btn btn-outline-primary" to={`/edit-address-footer/${c._id}`}> Edit Address</Link>)


                                            )
                                        }
                                    </>
                                }
                                {
                                    footeraddress.length === 0 &&

                                    <form class="contact-form " onSubmit={handlefooterAddress}>
                                        <div class="row">




                                            <div class="col-lg-12">
                                                <div class="contact-field p-relative c-name mb-20">

                                                    <input type="text" name="Location" placeholder=" Your Location" required />
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="contact-field p-relative c-name mb-20">

                                                    <input type="text" name="Phone" placeholder="Your Phone" required />
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="contact-field p-relative c-name mb-20">
                                                    <input type="text" name="footerEmail" placeholder=" Footer Email" required />
                                                </div>
                                            </div>



                                            <div class="slider-btn">
                                                <button class="btn btn-outline-primary" data-delay=".8s"> Add Footer Address</button>
                                            </div>
                                        </div>

                                    </form>

                                }

                            </div>


                        </div>
                    </div>


                </section>
            </div>

        </>
    );
};

export default FooterContact;