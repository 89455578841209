import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import './Banner.css';

const Banner = () => {
  const [banners, setBanners] = useState([]);
  useEffect(() => {
    fetch(`https://lead2cloneclone-36ae8168f906.herokuapp.com/banner`)
      .then((res) => res.json())
      .then((info) => setBanners(info));
  }, []);



  const handleSubscriber = (event) => {
    event.preventDefault();
    const subscriberEmail = event.target.subscriberEmail.value;

    const sunscribe = {
      subscriberEmail

    };

    const url = `https://lead2cloneclone-36ae8168f906.herokuapp.com/add-subscriber`;
    fetch(url, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(sunscribe),
    })
      .then((res) => res.json())
      .then((result) => {

        alert('Thanks For Subscribe..');
      });
  };



  return (




    <>

    {
      banners.map(b=> <div className="saas-home-area">
      <div className="d-table">
        <div className="d-table-cell">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-12">
                <div className="hero-content">
                  <span>{b.bannerText}</span>
                  <h1>{b.bannerHeading}</h1>
                  <p>{b.bannerDetails} </p>
                  <div className="saas-btn">
                    <a href={b.buttonURL} className="saas-btn-one">
                    {b.buttonText}
                      <i className="fa fa-arrow-right" />
                    </a>
                   
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="saas-image">
                  <img src={b.bannerImage} alt="image" />
                  <img className="banner-tab" src={b.bannerImageTwo}  alt="image" />
                </div>
              </div>
              <div className="circle-img">
                <img src="assets/img/saas-banner/circle.png" alt="image" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="shape-img1">
        <img src="assets/img/shape/1.svg" alt="image" />
      </div>
      <div className="shape-img2">
        <img src="assets/img/shape/2.svg" alt="image" />
      </div>
      <div className="shape-img3">
        <img src="assets/img/shape/3.png" alt="image" />
      </div>
      <div className="shape-img4">
        <img src="assets/img/shape/4.png" alt="image" />
      </div>
      <div className="shape-img5">
        <img src="assets/img/shape/6.png" alt="image" />
      </div>
    </div>)
    }

      



    </>
  );
};

export default Banner;
