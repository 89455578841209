// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBJF0UxkWeCdOJz5uPeHAbI62_Cf4jmDcU",
  authDomain: "lead2cloneclone.firebaseapp.com",
  projectId: "lead2cloneclone",
  storageBucket: "lead2cloneclone.appspot.com",
  messagingSenderId: "898962675921",
  appId: "1:898962675921:web:7bc233146da7b54da98df0"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const auth = getAuth(app);

export default auth;