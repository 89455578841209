import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const PricingSection = () => {

  const [pricing, setPricing] = useState([]);


  useEffect(() => {
    fetch(`https://lead2cloneclone-36ae8168f906.herokuapp.com/pricings`)
      .then((res) => res.json())
      .then((info) => setPricing(info));
  }, []);

  return (



    <>

      <section className="pricing-section pb-100" id='pricing-sec'>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12">
              <div className="pricing-area-content">
                <span>Pricing plan</span>
                <h3>Our Pricing is Affordable To all customer</h3>
                <p>Our pricing is designed to be affordable for all customers, ensuring accessibility to quality products/services without breaking the bank. We strive to provide the best value for your money, making our offerings accessible to a wide range of budgets. Quality and affordability are at the heart of our pricing strategy.</p>

              </div>
              <div className="pricing-btn-wrap">

              </div>
            </div>
            {
              pricing.map(b => b.packageName === 'Basic' && <div className="col-lg-3 col-md-6 col-sm-6">
                <div className="single-pricing-box">
                  <div className="pricing-header">
                    <h3>{b.packageName} Plan</h3>
                  </div>
                  <div className="price">
                    ${b.packagePrice} <span>USD</span>
                  </div>
                  <ul className="pricing-features">
                    <li>
                      <i className="fa fa-arrow-right" />
                      {b.packagePointOne}
                    </li>
                    <li>
                      <i className="fa fa-arrow-right" />
                      {b.packagePointTwo}
                    </li>
                    <li>
                      <i className="fa fa-arrow-right" />
                      {b.packagePointThree}
                    </li>
                    <li>
                      <i className="fa fa-arrow-right" />
                      {b.packagePointFour}
                    </li>
                    <li>
                      <i className="fa fa-arrow-right" />
                      {b.packagePointFive}
                    </li>
                    <li>
                      <i className="fa fa-arrow-right" />
                      {b.packagePointSix}
                    </li>
                  </ul>
                  <div className="buy-btn">

                    <Link to={`/order-now/${b._id}`} className="buy-btn-one"> {b.packageButtonText} </Link>
                  </div>
                </div>
              </div>)}


            {
              pricing.map(s => s.packageName === 'Premium' && <div className="col-lg-3 col-md-6 col-sm-6">
                <div className="single-pricing-box table-price">
                  <div className="pricing-header">
                    <h3>{s.packageName} Plan</h3>
                  </div>
                  <div className="price">
                    $ {s.packagePrice} <span>USD</span>
                  </div>
                  <ul className="pricing-features">
                    <li>
                      <i className="fa fa-arrow-right" />
                      {s.packagePointOne}
                    </li>
                    <li>
                      <i className="fa fa-arrow-right" />
                      {s.packagePointTwo}
                    </li>
                    <li>
                      <i className="fa fa-arrow-right" />
                      {s.packagePointThree}
                    </li>
                    <li>
                      <i className="fa fa-arrow-right" />
                      {s.packagePointFour}
                    </li>
                    <li>
                      <i className="fa fa-arrow-right" />
                      {s.packagePointFive}
                    </li>
                    <li>
                      <i className="fa fa-arrow-right" />
                      {s.packagePointSix}
                    </li>
                  </ul>
                  <div className="buy-btn">
                    <Link to={`/order-now/${s._id}`} className="buy-btn-one"> {s.packageButtonText} </Link>
                  </div>
                </div>
              </div>)}




          </div>
        </div>
      </section>



    </>
  );
};

export default PricingSection;